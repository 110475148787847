import React from 'react'
import TagText from '@component/Text/TagText'
import WizImage from '@component/images/WizImage'
import AppDownIOSIconEn from '@svg/common/ic_down_ios_en_gray.svg'
import AppDownAndroidIconEn from '@svg/common/ic_down_android_en_gray.svg'
import AppDownIOSIconKo from '@svg/common/ic_down_ios_ko_gray.svg'
import AppDownAndroidIconKo from '@svg/common/ic_down_android_ko_gray.svg'
import bannerImageSource from '@image/landing/img_welcome_app_download_logo.png'
import {useRouter} from 'next/router'
import {useTranslation} from 'next-i18next'
import {createLog} from '@util/logs'

interface IProps {
    className?: string
}

const TokenLandingAppDownloadContainer: React.FC<IProps> = ({className}) => {
    const language = useRouter()?.locale
    const {t} = useTranslation()

    const onClickAppStore = (os: string) => {
        window.open(t(`download.link.${os}`))
        createLog('event', 'landing_welcome_download_open_store_link', {os})
    }

    return (
        <div
            className={`flex flex-col items-center justify-center max-w-[1366px] mx-auto px-[20px] py-[150px] sm:hidden ${className} `}>
            <WizImage src={bannerImageSource} containerClassName={'max-w-[120px] sm:max-w-[76.36px]'} />
            <TagText
                className={
                    'typo-landing_display1 font-[400!important] md:typo-landing_body_medium text-white whitespace-pre-wrap text-center'
                }>
                {t('landingWelcome.supportExchanges.appDownload.title')}
            </TagText>
            <div className={'flex justify-center gap-x-[10px] mt-[30px] sm:mt-[10px]'}>
                <div
                    data-cy={'appStore'}
                    className={'max-w-[210px] md:flex-1 cursor-pointer'}
                    onClick={() => onClickAppStore('apple')}>
                    {language === 'ko' ? <AppDownIOSIconKo /> : <AppDownIOSIconEn />}
                </div>

                <div
                    data-cy={'playStore'}
                    className={'max-w-[210px] md:flex-1 cursor-pointer'}
                    onClick={() => onClickAppStore('android')}>
                    {language === 'ko' ? <AppDownAndroidIconKo /> : <AppDownAndroidIconEn />}
                </div>
            </div>
        </div>
    )
}

export default TokenLandingAppDownloadContainer
