import React, {useCallback} from 'react'
import PageConfig from '@component/layouts/PageConfig/PageConfig'
import TokenSaleContainer from '@feature/landing/pages/token/containers/TokenSaleContainer'
import TokenServiceContainer from '@feature/landing/pages/token/containers/service/TokenServiceContainer'
import TokenInfoContainer from '@feature/landing/pages/token/containers/token/TokenInfoContainer'
import useTokenLandingStore, {
    TokenLandingType,
    TokenLandingTypes,
} from '@feature/landing/pages/token/hooks/useTokenLandingStore'
import TokenLandingFAQContainer from '@feature/landing/pages/token/TokenLandingFAQContainer'
import TokenLandingAnnounceContainer from '@feature/landing/pages/token/TokenLandingAnnounceContainer'
import TokenLandingAppDownloadContainer from '@feature/landing/pages/token/TokenLandingAppDownloadContainer'

interface IProps {
    className?: string
}

const TokenLandingPageContainer: React.FC<IProps> = ({}) => {
    const {pageType} = useTokenLandingStore()
    const onSelectTabStyle = useCallback(
        (tab: TokenLandingType) =>
            pageType === tab
                ? 'relative opacity-1 z-10'
                : 'absolute top-0 left-0 right-0 opacity-0 pointer-events-none',
        [pageType],
    )

    return (
        <PageConfig
            enableFloatingNavigation
            navTheme={'dark'}
            navLogoType={'white'}
            footerTheme={'dark'}
            scrolledNavColor={'dark:bg-gray01 shadow-none'}
            mainClassName={'min-w-0'}
            bodyClassName={'bg-[#030304] dark'}>
            <TokenSaleContainer />
            <div className={'relative'}>
                <TokenServiceContainer className={onSelectTabStyle(TokenLandingTypes.SERVICE)} />
                <TokenInfoContainer className={onSelectTabStyle(TokenLandingTypes.TOKEN)} />
            </div>
            <TokenLandingAnnounceContainer />
            <TokenLandingFAQContainer />
            <TokenLandingAppDownloadContainer />
        </PageConfig>
    )
}
export default TokenLandingPageContainer
